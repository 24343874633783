<template>
  <div>
    <h4>Bestanden</h4>
    <b-table striped hover :items="items" :fields="fields">
      <template v-slot:cell(downloadlink)="data">
        <a href="#"><font-awesome-icon icon="download"/></a>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
export default {
  components: {
    BTable
  },
  props: {
    key: {
      required: true,
      type: String,
      default: function() {
        return Math.floor(Math.random() * 100000) + 1
      }
    },
    type: {
      required: true,
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      items: [
        {
          filename: `NEN2484 - Keuring ${Math.floor(Math.random() * 100000) +
            1}-KD.pdf`,
          created: '13-01-2021',
          downloadlink: ''
        },
        {
          filename: `NEN2484 - Keuring ${Math.floor(Math.random() * 100000) +
            1}-ZN.pdf`,
          created: '13-03-2020',
          downloadlink: ''
        },
        {
          filename: `NEN2484 - Keuring ${Math.floor(Math.random() * 100000) +
            1}-GE.pdf`,
          created: '04-12-2019',
          downloadlink: ''
        },
        {
          filename: `NEN2484 - Keuring ${Math.floor(Math.random() * 100000) +
            1}-BA.pdf`,
          created: '23-02-2019',
          downloadlink: ''
        }
      ],
      fields: [
        {
          key: 'filename',
          label: 'Bestand'
        },
        {
          key: 'created',
          label: 'Geupload op'
        },
        {
          key: 'downloadlink',
          label: 'Download'
        }
      ]
    }
  }
}
</script>
