<template>
  <div>
    <h4>
      Serienummer gegevens
    </h4>
    <table class="table">
      <tr>
        <td><strong>Serienummer:</strong></td>
        <td>{{ $route.params.serialid }}</td>
      </tr>
      <tr>
        <td><strong>Opmerking:</strong></td>
        <td>Integer ornare neque leo, in interdum massa malesuada ac.</td>
      </tr>
      <tr>
        <td><strong>Serienummer leverancier:</strong></td>
        <td>GD49846518948</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
