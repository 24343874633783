<template>
  <b-container>
    <b-row>
      <b-col cols="12"
        ><h1>Serienummer: {{ $route.params.serialid }}</h1>
        <hr />
      </b-col>
      <b-col md="6" xs="12">
        <item-info />
      </b-col>
      <b-col md="6" xs="12">
        <serial-info />
      </b-col>
      <b-col>
        <hr />
        <serial-service-info />

        <hr />
        <file-download :key="key" :type="type" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import FileDownload from '../Account/FileDownload'

import ItemInfo from '@/components/item/ItemInfo'
import SerialInfo from '@/components/serial/SerialInfo'
import SerialServiceInfo from '@/components/serial/SerialServiceInfo'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    FileDownload,

    ItemInfo,
    SerialInfo,
    SerialServiceInfo
  },
  data: function() {
    return {
      serialid: 'serial'
    }
  }
}
</script>

<style></style>
