<template>
  <div>
    <h4>
      Servicebeurten
    </h4>
    <b-table :items="services" :fields="fields" />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
export default {
  components: {
    BTable
  },
  data: function() {
    return {
      services: [
        {
          serviceID: 20,
          serviceType: 'Jaarlijkse keuring',
          status: 'Ingepland',
          serviceDate: 'N/A',
          expectedServiceDate: '12-04-2021',
          followupStatus: 'In afwachting van service'
        },
        {
          serviceID: 18,
          serviceType: 'Jaarlijkse keuring',
          status: 'Uitgevoerd',
          expectedServiceDate: '30-04-2020',
          serviceDate: '04-06-2020',
          followupStatus: 'Jaarlijkse keurig - afgerond'
        },
        {
          serviceID: 6,
          serviceType: 'Jaarlijkse keuring',
          status: 'Uitgevoerd',
          expectedServiceDate: '12-04-2019',
          serviceDate: '12-04-2019',
          followupStatus: 'Jaarlijkse keurig - afgerond'
        },
        {
          serviceID: 2,
          serviceType: 'Jaarlijkse keuring',
          status: 'Uitgevoerd',
          expectedServiceDate: '20-03-2018',
          serviceDate: '20-03-2018',
          followupStatus: 'Jaarlijkse keurig - afgerond'
        }
      ],
      fields: [
        {
          key: 'serviceID',
          label: 'Bestand'
        },
        {
          key: 'serviceType',
          label: 'Geupload op'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'serviceDate',
          label: 'Service uitgevoerd op'
        },
        {
          key: 'expectedServiceDate',
          label: 'Ingeplande service datum'
        },
        {
          key: 'followupStatus',
          label: 'Huidige opvolgstatus'
        }
      ]
    }
  }
}
</script>

<style></style>
