<template>
  <div>
    <h4>
      Artikel gegevens
    </h4>
    <table class="table">
      <tr>
        <td><strong>Artikelnummer:</strong></td>
        <td>Hitachi-k-x90</td>
      </tr>
      <tr>
        <td><strong>Omschrijving:</strong></td>
        <td>Hitachi klopboor x90</td>
      </tr>
      <tr>
        <td><strong>Transport gewicht:</strong></td>
        <td>45</td>
      </tr>
      <tr>
        <td><strong>Gewicht:</strong></td>
        <td>40</td>
      </tr>
    </table>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at nibh
      diam. Maecenas vitae sollicitudin urna, a consectetur tellus.
    </p>
    <p>
      Integer porta mi nec efficitur hendrerit. Fusce ex ligula, imperdiet
      commodo posuere vel, eleifend eget sapien. Praesent tincidunt eros sed
      quam elementum, vitae luctus magna faucibus. Integer ut vestibulum purus.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
